<template>
  <div class="row justify-content-center" v-if="!fetching">
    <div class="candidate-profile col-sm-9">
      <div class="d-flex align-items-center justify-content-between flex-wrap">
        <h3 class="m-1 pt-1 text-primary">Create Job</h3>
        <div class="w-100 d-flex justify-content-start sticky-header pt-3">
          <Stepper
            class="mb-3"
            :options="stepperOptions"
            :activeBlock="block"
            :goTo="slideTo"
          >
          </Stepper>
          <h6
            v-if="getDisplayJobID"
            class="pb-2 text-primary mt-1 font-weight-bold"
            style="white-space: nowrap"
          >
            JOB ID: {{ getDisplayJobID }}
          </h6>
        </div>
      </div>
      <h6
        v-if="(getIsParentUser && isChildOrg) || showHintOrgName"
        class="text-primary text-right"
      >
        You are creating a job for <strong>{{ getOrgName }}</strong>
      </h6>
      <span v-if="block == 0">
        <JobInfo
          :isJobSupplier="isJobSupplier"
          @step="gotoStep"
          @showHintOrgName="showHintOrgName = $event"
          @tempJob="tempJob = $event"
        />
      </span>
      <span v-if="block == 1">
        <JobAdditionalInfo @step="gotoStep" @tempJob="tempJob = $event" />
      </span>
      <span v-if="block == 2">
        <JobBenefits @step="gotoStep" @tempBenefits="tempBenefits = $event" />
      </span>
      <span v-if="block == 3">
        <JobTemplate
          @step="gotoStep"
          @tempSectionsContent="tempSectionsContent = $event"
          @getImageUrl="getImageUrl = $event"
        />
      </span>
      <span v-if="block == 4">
        <JobPreview @step="gotoStep" />
      </span>
    </div>
    <div :class="isMobile ? 'col-sm-9' : 'col-sm-3'" v-show="block != 4">
      <h6 class="text-right mt-2 preview-text">
        <CButton
          class="text-primary"
          color="link"
          style="text-decoration: none"
          @click="showModalPreview = true"
        >
          <em class="fas fa-eye"></em>
          Preview
        </CButton>
      </h6>
      <MicroPreview
        :jobDetails="jobById"
        :tempJob="tempJob"
        :tempSectionsContent="tempSectionsContent"
        :tempBenefits="tempBenefits"
        :getImageUrl="getImageUrl"
        @showModalPreview="
          !isMobile ? (showModalPreview = true) : (showModalPreview = false)
        "
        className="micro-preview"
      />
    </div>
    <CModal
      id="preview - Modal"
      title="Preview"
      color="primary"
      :show.sync="showModalPreview"
      size="lg"
    >
      <MicroPreview
        :jobDetails="jobById"
        :tempJob="tempJob"
        :tempSectionsContent="tempSectionsContent"
        :tempBenefits="tempBenefits"
        :getImageUrl="getImageUrl"
        className="macro-preview"
      />
      <template #footer>
        <div>
          <CButton color="secondary" @click="showModalPreview = false">
            Close
          </CButton>
        </div>
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Stepper from "@/components/reusable/bars/stepper.vue";
import JobInfo from "@/containers/AddJobs/JobInformation";
import JobTemplate from "@/containers/AddJobs/JobTemplate";
import JobAdditionalInfo from "@/containers/AddJobs/JobAdditionalInfo";
import JobBenefits from "@/containers/AddJobs/JobBenefits";
import JobPreview from "@/containers/AddJobs/jobPreview";
import isJobSupplier from "@/mixins/isJobSupplier";
import MicroPreview from "@/containers/AddJobs/MicroPreview";

export default {
  name: "AddJobs",
  mixins: [isJobSupplier],
  components: {
    Stepper,
    JobInfo,
    JobAdditionalInfo,
    JobBenefits,
    JobTemplate,
    JobPreview,
    MicroPreview,
  },
  data() {
    return {
      block: 0,
      stepperOptions: {
        headers: [
          {
            title: "",
            tooltipTitle: "Job Information",
            tooltipPlacement: "bottom-start",
            disabled: false,
          },
          {
            title: "",
            tooltipTitle: "Additional Information",
            tooltipPlacement: "bottom-start",
            disabled: false,
          },
          {
            title: "",
            tooltipTitle: "Job Benefits",
            tooltipPlacement: "bottom-start",
            disabled: false,
          },
          {
            title: "",
            tooltipTitle: "Create Job",
            tooltipPlacement: "bottom-start",
            disabled: false,
          },
          {
            title: "",
            tooltipTitle: "Publish Job",
            tooltipPlacement: "bottom-start",
            disabled: false,
          },
        ],
      },
      fetching: false,
      jobId: null,
      showHintOrgName: "",
      tempJob: {},
      tempBenefits: [],
      tempSectionsContent: [],
      getImageUrl: "",
      showModalPreview: false,
      isMobile: false,
      isEditJob: false,
    };
  },
  computed: {
    ...mapGetters([
      "getCustomerId",
      "jobById",
      "getIsChildUser",
      "getOrgID",
      "getIsParentUser",
    ]),
    getOrgName() {
      return this.jobById?.organisation_name || this.showHintOrgName;
    },
    isChildOrg() {
      return this.jobById.organisation?.parent_org_id ? true : false;
    },
    getDisplayJobID() {
      let { display_uid } = this.jobById;
      return display_uid || null;
    },
  },
  created() {
    this.fetchInitialData();
  },
  mounted() {
    let isMobile = window.matchMedia(
      "only screen and (max-width: 768px)"
    ).matches;
    this.isMobile = isMobile;
    if (this.$router.currentRoute.name == "EditJob") this.isEditJob = true;
  },
  methods: {
    ...mapActions([
      "showLoader",
      "hideLoader",
      "initJobsFetchOptions",
      "fetchJobByIDWithInitialOptions",
      "resetJobByID",
      "fetchOrganizationId",
    ]),
    slideTo(event, obj) {
      if (event < this.block || this.isEditJob) {
        this.updateURLQuery(event);
      } else {
        setTimeout(() => {
          obj.currentPosition = this.block;
        }, 0);
      }
    },
    gotoStep(step) {
      this.updateURLQuery(step);
      this.$store.dispatch("scrollTop");
    },
    goToJobBoard() {
      this.$router.push({ path: "/jobs-list/status/active" });
    },
    updateURLQuery(step = 0) {
      let { query } = this.$route;
      this.$router.push({ query: { ...query, step: step } });
    },
    fetchInitialData() {
      this.fetching = true;
      this.showLoader();
      const {
        query: { id: qid },
        params: { id: pid },
      } = this.$route;
      this.jobId = qid || pid;
      // Fetching initial data for job
      this.initJobsFetchOptions().then(async () => {
        if (this.jobId) {
          // Fetching job data
          await this.fetchJobByIDWithInitialOptions(this.jobId);
        } else {
          // Clearing cache
          this.resetJobByID();
        }
        let organisation_id = null;
        if (this.getIsChildUser && this.getOrgID)
          organisation_id = this.getOrgID || null;
        if (this.getCustomerId) {
          await this.fetchOrganizationId({
            customer_uid: this.getCustomerId,
            organisation_id,
          });
        }
        this.hideLoader();
        this.fetching = false;
      });
      let { query } = this.$route;
      if (!query?.step) {
        this.$router.push({ query: { step: 0 } });
      } else {
        this.block = parseInt(query?.step);
      }
    },
  },
  watch: {
    "$route.query": function () {
      let {
        name,
        query: { id, step },
      } = this.$route;
      if (!id && step && name == "AddJobs") {
        this.resetJobByID();
        this.updateURLQuery();
        this.fetchInitialData();
        this.tempJob = {};
        this.tempBenefits = [];
        this.tempSectionsContent = [];
        this.getImageUrl = "";
      }
      this.block = parseInt(step);
    },
  },
};
</script>
<style lang="scss" scoped>
@media (max-width: 768px) {
  .preview-text {
    display: none;
  }
}
</style>
